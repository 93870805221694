import { userManualSections } from '@/features/userManual/utils';
import { IPagesForUserManualDialog } from '@/features/userManual/utils';
import UserManualFunctionDialogContent from '@/features/userManual/components/UserManualFunctionDialogContent';
import WelcomePageDialogContent from '@/features/userManual/components/WelcomePageDialogContent';

export const UserManualPages = ({
  keyPage,
  imageSrc,
  listKeys,
  listSubtitles,
}: IPagesForUserManualDialog) => {
  switch (keyPage) {
    case userManualSections.functions.overview:
    case userManualSections.functions.chargingCards:
    case userManualSections.functions.activateCard:
    case userManualSections.functions.chargingCardAlias:
    case userManualSections.functions.orderChargingCard:
    case userManualSections.functions.contractInformation:
    case userManualSections.functions.invoiceDetails:
    case userManualSections.functions.chargingSessions:
    case userManualSections.functions.detailChargingSessions:
    case userManualSections.functions.invoices:
      return (
        <UserManualFunctionDialogContent
          keyPage={keyPage}
          imageSrc={imageSrc}
          listKeys={listKeys}
        />
      );
    case userManualSections.service.welcome:
      return (
        <WelcomePageDialogContent
          keyPage={keyPage}
          imageSrc={imageSrc}
          listKeys={listKeys}
          listSubtitles={listSubtitles}
        />
      );
    default:
      return (
        <WelcomePageDialogContent
          keyPage={keyPage}
          imageSrc={imageSrc}
          listKeys={listKeys}
          listSubtitles={listSubtitles}
        />
      );
  }
};
