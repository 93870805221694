import { logError } from '@/lib/logger';
import { useTokenStore } from '@/stores/tokenStore';
import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import { v4 as randomUUID } from 'uuid';

const createAxiosConfig = () => {
  const baseURL = import.meta.env.PROD
    ? import.meta.env.VITE_EMSP_API_URL
    : '/api';

  return {
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  };
};

const addAuthHeaders = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const { accessToken } = useTokenStore.getState();

  const headers = AxiosHeaders.concat(config.headers, {
    Authorization: accessToken ? `Bearer ${accessToken}` : '',
    'X-Flow-ID': randomUUID(),
  });

  return {
    ...config,
    headers,
  };
};

export const axiosInstance = axios.create(createAxiosConfig());

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => addAuthHeaders(config),
  (error: Error) => {
    logError({
      message: 'Request interceptor error',
      error,
      context: { config: error },
    });
    return Promise.reject(error);
  }
);
