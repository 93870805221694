import { getAllowedCountries } from '@/stores/countryStore';
import defaultTo from 'lodash/fp/defaultTo';
import flow from 'lodash/fp/flow';
import has from 'lodash/fp/has';
import head from 'lodash/fp/head';
import split from 'lodash/fp/split';

export const DEFAULT_LOCALE = 'en-GB';

type LocaleMap = Record<string, string>;

export const supportedLocaleMap: LocaleMap = {
  bg: 'bg-BG',
  'bg-BG': 'bg-BG',
  cs: 'cs-CZ',
  'cs-CZ': 'cs-CZ',
  da: 'da-DK',
  'da-DK': 'da-DK',
  de: 'de-DE',
  'de-DE': 'de-DE',
  el: 'el-GR',
  'el-GR': 'el-GR',
  en: 'en-GB',
  'en-GB': 'en-GB',
  es: 'es-ES',
  'es-ES': 'es-ES',
  et: 'et-EE',
  'et-EE': 'et-EE',
  fi: 'fi-FI',
  'fi-FI': 'fi-FI',
  fr: 'fr-FR',
  'fr-FR': 'fr-FR',
  hr: 'hr-HR',
  'hr-HR': 'hr-HR',
  hu: 'hu-HU',
  'hu-HU': 'hu-HU',
  it: 'it-IT',
  'it-IT': 'it-IT',
  'it-LT': 'it-LT',
  lv: 'lv-LV',
  'lv-LV': 'lv-LV',
  nb: 'nb-NO',
  'nb-NO': 'nb-NO',
  nl: 'nl-NL',
  'nl-NL': 'nl-NL',
  pl: 'pl-PL',
  'pl-PL': 'pl-PL',
  pt: 'pt-PT',
  'pt-PT': 'pt-PT',
  'pt-BR': 'pt-BR',
  ro: 'ro-RO',
  'ro-RO': 'ro-RO',
  sk: 'sk-SK',
  'sk-SK': 'sk-SK',
  sl: 'sl-SL',
  'sl-SL': 'sl-SL',
  se: 'sv-SE',
  'sv-SE': 'sv-SE',
};

export const extractLanguage = (locale: string | undefined): string => {
  if (!locale) {
    return 'en';
  }
  return flow(split('-'), head, defaultTo(DEFAULT_LOCALE))(locale);
};

export const DEFAULT_LANG = extractLanguage(DEFAULT_LOCALE);

export const getSupportedLocale = (preferredLocale: string): string =>
  has(preferredLocale, supportedLocaleMap)
    ? supportedLocaleMap[preferredLocale]
    : DEFAULT_LOCALE;

export const isLanguageSupported = (language: string | undefined): boolean => {
  if (!language) {
    return false;
  }

  const countries = getAllowedCountries();

  const checkLanguage = (lang: string): boolean => countries.includes(lang);

  // First, check if the full language code exists
  if (checkLanguage(language)) {
    return true;
  }

  // If not, extract the language part and check again
  const extractedLang = extractLanguage(language);
  return checkLanguage(extractedLang);
};

export const getFlagEmoji = (countryCode: string): string =>
  countryCode.replace(/./g, (char) =>
    String.fromCodePoint(127397 + char.charCodeAt(0))
  );

export const LanguagesCodes = [
  ...new Set(Object.values(supportedLocaleMap)),
].filter((v) => isNaN(Number(v))) as string[];

export const formattedLanguageName = (locale: string): string => {
  const countryCode = locale.slice(3);

  return `${getFlagEmoji(countryCode)} ${new Intl.DisplayNames(['en'], {
    type: 'region',
  }).of(countryCode)} (${locale})`;
};
