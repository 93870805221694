import {
  EVENT_USER_LANGUAGE_CHANGED,
  EVENT_USER_PROFILE_CHANGED,
} from '@rio-cloud/rio-user-menu-component';
import type { UserManager } from 'oidc-client-ts';
import { createIntl, createIntlCache } from 'react-intl';

import { useAccountStore } from '@/stores/accountStore';
import { useLocaleStore } from '@/stores/localeStore';
import { useTokenStore } from '@/stores/tokenStore';
import { useUserStore } from '@/stores/userStore';

import { extractLanguage, getSupportedLocale } from '@/configuration/lang/lang';
import {
  configureFetchDisplayMessages,
  importDisplayMessages,
} from '@/configuration/lang/services';
import {
  type SessionRenewedResult,
  configureUserManager,
  createUserManager,
} from '@/configuration/login/login';
import { initDatadog } from '@/configuration/setup/datadog';
import { initializeAnalytics } from '@/configuration/setup/googleAnalytics';
import { attemptInitialSignIn } from '@/configuration/setup/oauth';
import { trace } from '@/configuration/setup/trace';
import { accessToken } from '@/configuration/tokenHandling/accessToken';

import { fetchAccount } from '@/api/admin';

import { logError } from '@/lib/logger';
import { initializeCountryStore } from '@/stores/countryStore';

export interface OAuthConfig {
  onSessionExpired: Function;
  onSessionRenewed: Function;
}

const fetchDisplayMessages = configureFetchDisplayMessages();

export const oauthConfig = {
  onSessionExpired: () => {
    accessToken.discardAccessToken();
    useAccountStore.getState().setUserSessionExpired();
  },
  onSessionRenewed: async (result: SessionRenewedResult) => {
    trace('index.onTokenRenewed', result);

    useTokenStore.getState().updateToken(result.accessToken);
    useTokenStore.getState().updateIdToken(result.idToken);

    initDatadog();
    initializeAnalytics();

    const accountInfo = await fetchAccount(result.accessToken, result.profile);

    if (accountInfo) {
      // Country Locale logic, intl instance created from company's country code
      const cache = createIntlCache();
      const countryLocale = new Intl.Locale(accountInfo.address.countryCode);

      const parsedLocale = getSupportedLocale(countryLocale.baseName);
      const intl = createIntl(
        {
          locale: parsedLocale,
          messages: await importDisplayMessages(parsedLocale),
        },
        cache
      );

      initializeCountryStore(accountInfo.address.countryCode);

      const parsedCountryLocale =
        ['DE', 'AT'].indexOf(accountInfo.address.countryCode) !== -1
          ? 'de'
          : 'en';

      useUserStore.getState().setCountryIntl(parsedCountryLocale, intl);

      useUserStore.getState().setAccount(accountInfo);
    }

    useAccountStore.getState().setUserProfile(result.profile);
    useAccountStore.getState().setUserSessionRenewed();

    // You will need to get the user language yourself then
    // you may fetch the suitable messages. Depending
    // on when and from where you fetch the user settings you might
    // want to employ a loading spinner while the request is ongoing.
    await fetchDisplayMessages(result.locale);
  },
} as OAuthConfig;

export const main = async (renderApp: Function) => {
  const userManager: UserManager = configureUserManager(
    oauthConfig,
    createUserManager()
  );

  const signinSilent = userManager.signinSilent.bind(userManager);
  document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, () => {
    const lang = extractLanguage(useLocaleStore.getState().displayLocale);
    const html = document.querySelector('html');

    if (html && lang && html.getAttribute('lang') !== lang) {
      html.setAttribute('lang', lang);
    }
    signinSilent();
  });
  document.addEventListener(EVENT_USER_PROFILE_CHANGED, () => signinSilent());

  try {
    await userManager.clearStaleState();
    await attemptInitialSignIn(userManager);
    renderApp();
  } catch (error) {
    trace('could not start application', error);
    logError({ message: 'could not start application', error: error as Error });
  }
};
