import { Spinner } from '@rio-cloud/rio-uikit';
import DOMPurify from 'dompurify';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IPagesForUserManualDialog } from '@/features/userManual/utils';

import { cn, t } from '@/lib/utils';

const UserManualFunctionDialogContent = React.memo(
  ({ keyPage, imageSrc, listKeys }: IPagesForUserManualDialog) => {
    const intl = useIntl();
    const [showSpinner, setShowSpinner] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);

    const sanitizeHtml = useCallback(
      (key: string) => {
        const translatedHTML = t(key, {}, intl);
        return DOMPurify.sanitize(translatedHTML);
      },
      [intl]
    );

    useEffect(() => {
      setShowSpinner(true);
      setImageLoaded(false);
    }, [imageSrc]);

    const handleImageLoad = () => {
      setImageLoaded(true);
      setShowSpinner(false);
    };

    const noteExists =
      t(`userManual.${keyPage}.note`) !== `userManual.${keyPage}.note`;

    return (
      <>
        <h1
          data-test-id="user-manual-modal-title"
          className="text-4xl font-normal normal-case"
        >
          <FormattedMessage id={`userManual.${keyPage}.title`} />
        </h1>
        <div className="relative aspect-auto w-full h-[410px]">
          {showSpinner && (
            <div className="w-full h-full relative">
              <Spinner />
            </div>
          )}
          <img
            src={imageSrc}
            alt={t(`userManual.${keyPage}.imageAlt`)}
            data-test-id="user-manual-modal-image"
            height={410}
            className={cn(
              'w-full h-full object-contain',
              imageLoaded ? 'opacity-100' : 'opacity-0'
            )}
            onLoad={handleImageLoad}
          />
        </div>

        <h2
          data-test-id="user-manual-modal-subtitle"
          className="text-xl font-bold"
        >
          <FormattedMessage id={`userManual.${keyPage}.subtitle`} />
        </h2>
        <ol className="ml-0 list-outside list-decimal pl-0 [counter-reset:li]">
          {listKeys.map((key, index) => (
            <li
              key={index}
              className="text-md
                      relative
                      my-3
                      block
                      min-h-12
                      overflow-hidden
                      pl-16
                      pt-1
                      before:absolute
                      before:left-0
                      before:top-1/2
                      before:-translate-y-1/2
                      before:h-[30px]
                      before:w-[30px]
                      before:rounded-full
                      before:bg-[#B23672]
                      before:flex
                      before:items-center
                      before:justify-center
                      before:text-center
                      before:leading-[2.9rem]
                      before:text-white
                      before:content-[counter(li)]
                      before:[counter-increment:li]
                    "
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(`userManual.${keyPage}.list.${key}`),
              }}
            />
          ))}
        </ol>
        {noteExists && (
          <p
            className="text-md"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(`userManual.${keyPage}.note`),
            }}
          />
        )}
      </>
    );
  }
);

UserManualFunctionDialogContent.displayName =
  'UserManualFunctionDialogContent';

export default UserManualFunctionDialogContent;
