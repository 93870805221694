import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  DEFAULT_ROUTE,
  NOT_AVAILABLE,
  NOT_FOUND,
  SIGNUP,
  routes,
} from '@/routes/Router';

import { isLanguageSupported } from '@/configuration/lang/lang';

import { useUserStore } from '@/stores/userStore';

import { AccountType } from '@/api/schema';

import { usePageTracking } from '@/hooks/usePageTracking';

const isKnownRoute = (pathname: string): boolean =>
  routes.some((route) => pathname === route || pathname.startsWith(route));

const getRedirectPath = (
  pathname: string,
  account: AccountType | null
): string => {
  if (!isLanguageSupported(account?.address.countryCode)) {
    return NOT_AVAILABLE;
  }

  if (!account?.isOnboarded) {
    return SIGNUP;
  }

  if (pathname === '/') {
    return DEFAULT_ROUTE;
  }

  if (!isKnownRoute(pathname)) {
    return NOT_FOUND;
  }

  return pathname;
};

const DefaultRedirect = () => {
  const { pathname } = useLocation();
  const { account } = useUserStore();
  const navigate = useNavigate();

  usePageTracking();

  useEffect(() => {
    const redirectPath = getRedirectPath(pathname, account);
    if (redirectPath !== pathname) {
      navigate(redirectPath);
    }
  }, [pathname, account, navigate]);

  return null;
};

export default DefaultRedirect;
