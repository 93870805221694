import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Dialog,
  ListMenu,
  OverlayTrigger,
  Tooltip,
} from '@rio-cloud/rio-uikit';

import {
  pagesForServiceSectionOfUserManualDialog,
  pagesForFunctionSectionOfUserManualDialog,
} from '@/features/userManual/utils';

import { DialogType, useModal } from '@/hooks/useModal';

import { cn, t } from '@/lib/utils';
import { UserManualPages } from '@/features/userManual/components/UserManualPages';

export const UserManualButton = () => {
  const { openDialog } = useModal();

  const [show, setShow] = useState(false);
  const [selectedPageIndex, setSelectedPageIndex] = useState<string | null>(
    null
  );

  const handleMenuItemClick = useCallback((pageKey: string) => {
    setSelectedPageIndex(pageKey);
  }, []);

  const menuItems = useMemo(
    () => [
      {
        group: <FormattedMessage id="userManual.menu.service.title" />,
        navItems: pagesForServiceSectionOfUserManualDialog.map(
          ({ keyPage }) => ({
            key: keyPage,
            item: (
              <span
                className={cn(
                  keyPage === selectedPageIndex &&
                    'active !border-0 !border-l-2',
                  'hover:!border-0 hover:!border-l-2'
                )}
                onClick={() => handleMenuItemClick(keyPage)}
              >
                <FormattedMessage id={`userManual.sections.${keyPage}.title`} />
              </span>
            ),
          })
        ),
      },
      {
        group: <FormattedMessage id="userManual.menu.title" />,
        navItems: pagesForFunctionSectionOfUserManualDialog.map(
          ({ keyPage }) => ({
            key: keyPage,
            item: (
              <span
                className={cn(
                  keyPage === selectedPageIndex &&
                    'active !border-0 !border-l-2',
                  'hover:!border-0 hover:!border-l-2'
                )}
                onClick={() => handleMenuItemClick(keyPage)}
              >
                <FormattedMessage id={`userManual.${keyPage}.title`} />
              </span>
            ),
          })
        ),
      },
    ],
    [t, selectedPageIndex, handleMenuItemClick]
  );

  const handleClose = () => {
    setSelectedPageIndex(null);
    setShow(false);
  };

  const findSelectedPage = (pageIndex: string | null) => {
    return (
      pagesForFunctionSectionOfUserManualDialog.find(
        (page) => page.keyPage === pageIndex
      ) ||
      pagesForServiceSectionOfUserManualDialog.find(
        (page) => page.keyPage === pageIndex
      )
    );
  };

  useEffect(() => {
    if (selectedPageIndex == null) {
      return;
    }

    const selectedPage = findSelectedPage(selectedPageIndex);

    openDialog({
      type: DialogType.SPLIT,
      show,
      onClose: handleClose,
      title: <FormattedMessage id="userManual.modalTitle" />,
      bsSize: Dialog.SIZE_XL,
      leftContent: (
        <ListMenu menuItems={menuItems} groupClassName="padding-left-0" />
      ),
      rightContent: selectedPage && (
        <UserManualPages
            keyPage={selectedPage.keyPage}
            imageSrc={selectedPage.imageSrc}
            listKeys={selectedPage.listKeys}
            listSubtitles={selectedPage.listSubtitles}
        />
      ),
    });
  }, [selectedPageIndex, openDialog, menuItems, show]);

  const openDialogHelper = useCallback(() => {
    setSelectedPageIndex(pagesForServiceSectionOfUserManualDialog[0].keyPage);
    setShow(true);
  }, []);

  return (
    <OverlayTrigger
      placement={'bottom'}
      overlay={
        <Tooltip id="tooltip" allowOnTouch>
          <FormattedMessage id="general.userManual" />
        </Tooltip>
      }
    >
      <span
        className="text-size-h3 icon rioglyph rioglyph-info-sign pl-2"
        data-test-id="user-manual-btn"
        onClick={openDialogHelper}
      />
    </OverlayTrigger>
  );
};
