import { ZodError } from 'zod';

import { logError } from '@/lib/logger';
import { AxiosError } from 'axios';

type ErrorContext = Record<string, unknown>;

export const createApiError = (
  message: string,
  status?: number,
  data?: unknown
) => ({
  type: 'API_ERROR' as const,
  message,
  status,
  data,
});

export const createValidationError = (message: string, errors: unknown) => ({
  type: 'VALIDATION_ERROR' as const,
  message,
  errors,
});

export const handleApiError = (error: unknown, context?: ErrorContext) => {
  if (error && typeof error === 'object' && 'type' in error) {
    return error;
  }

  logError({
    message: error instanceof Error ? error.message : 'Unknown API error',
    error: error instanceof Error ? error : new Error(String(error)),
    context,
  });

  if (error instanceof AxiosError) {
    return createApiError(
      error.message,
      error.response?.status,
      error.response?.data
    );
  }

  if (error instanceof ZodError) {
    return createValidationError('Response validation failed', error.errors);
  }

  return createApiError('Unknown error occurred');
};
