import { Spinner } from '@rio-cloud/rio-uikit';
import DOMPurify from 'dompurify';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IPagesForUserManualDialog } from '@/features/userManual/utils';

import { cn, t } from '@/lib/utils';

const WelcomePageDialogContent = React.memo(
  ({
    keyPage,
    imageSrc,
    listSubtitles,
    listKeys,
  }: IPagesForUserManualDialog) => {
    const intl = useIntl();
    const [showSpinner, setShowSpinner] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);

    const sanitizeHtml = useCallback(
      (key: string) => {
        const translatedHTML = t(key, {}, intl);
        return DOMPurify.sanitize(translatedHTML);
      },
      [intl]
    );

    useEffect(() => {
      setShowSpinner(true);
      setImageLoaded(false);
    }, [imageSrc]);

    const handleImageLoad = () => {
      setImageLoaded(true);
      setShowSpinner(false);
    };

    const footerExists =
      t(`userManual.${keyPage}.footer`) !== `userManual.${keyPage}.footer`;

    return (
      <>
        <h1
          data-test-id="user-manual-service-modal-title"
          className="text-4xl font-normal normal-case"
        >
          <FormattedMessage id={`userManual.${keyPage}.title`} />
        </h1>
        <div className="relative aspect-auto w-full h-[410px]">
          {showSpinner && (
            <div className="w-full h-full relative">
              <Spinner />
            </div>
          )}
          <img
            src={imageSrc}
            alt={t(`userManual.${keyPage}.imageAlt`)}
            data-test-id="user-manual-modal-image"
            height={410}
            className={cn(
              'w-full h-full object-contain',
              imageLoaded ? 'opacity-100' : 'opacity-0'
            )}
            onLoad={handleImageLoad}
          />
        </div>

        <div className="ml-0">
          {listSubtitles?.map((subtitleKey) => (
            <div key={subtitleKey} className="margin-bottom-25">
              <p className="text-size-h4 font-bold">
                <FormattedMessage id={`userManual.${keyPage}.${subtitleKey}`} />
              </p>
              {listKeys
                .filter((desc) => desc.includes(subtitleKey))
                .map((key, index) => (
                  <p
                    key={index}
                    className="text-md
                          relative
                          my-3
                          block
                          min-h-12
                          overflow-hidden
                        "
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(`userManual.${keyPage}.${key}`),
                    }}
                  />
                ))}
            </div>
          ))}
        </div>
      </>
    );
  }
);

WelcomePageDialogContent.displayName = 'UserManualDialogContent';

export default WelcomePageDialogContent;
