import { create } from 'zustand';

// Base allowed countries without TV
const baseAllowedCountries = [
  'DE', // Germany
  'AT', // Austria
  'FR', // France
  'SE', // Sweden
  'CH', // Switzerland
  'NL', // Netherlands
  'NO', // Norway
  'BE', // Belgium
  'GB', // United Kingdom
  'PL', // Poland
  'ES', // Spain
  'DK', // Denmark
  'IT', // Italy
  'RO', // Romania
  'CZ', // Czech Republic
];

// Store to manage the TV country availability
interface CountryStore {
  includeTuvalu: boolean;
  setIncludeTuvalu: (include: boolean) => void;
  getAllowedCountries: () => string[];
}

export const useCountryStore = create<CountryStore>((set, get) => ({
  includeTuvalu: false,
  setIncludeTuvalu: (include) => set({ includeTuvalu: include }),
  getAllowedCountries: () => {
    const { includeTuvalu } = get();
    return includeTuvalu
      ? [...baseAllowedCountries, 'TV']
      : baseAllowedCountries;
  },
}));

// Helper function to initialize the country store based on initial state
export const initializeCountryStore = (initialCountry?: string) => {
  const shouldIncludeTuvalu = initialCountry === 'TV';
  useCountryStore.getState().setIncludeTuvalu(shouldIncludeTuvalu);
};

// Export a function to get the current allowed countries
export const getAllowedCountries = () => {
  return useCountryStore.getState().getAllowedCountries();
};
