import {
  Route,
  createHashRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { suspendPageLoad } from '@/routes/suspendPageLoad';

import AppLayout from '@/layout/AppLayout';

import { ErrorBoundary } from '@/components/ErrorBoundary';

// Lazy load pages for better performance and automatically split the bundle accordingly
const DashboardPage = suspendPageLoad(
  () => import('@/pages/dashboard/Dashboard')
);
const CardsPage = suspendPageLoad(() => import('@/pages/administration/Cards'));
const ContractPage = suspendPageLoad(
  () => import('@/pages/administration/Contract')
);
const InvoicesPage = suspendPageLoad(() => import('@/pages/charging/Invoices'));
const SessionsPage = suspendPageLoad(() => import('@/pages/charging/Sessions'));
const SignupPage = suspendPageLoad(() => import('@/pages/signup/Signup'));
const NotFound = suspendPageLoad(() => import('@/pages/NotFound'));
const NoPermissions = suspendPageLoad(() => import('@/pages/NoPermissions'));
const NotBookedState = suspendPageLoad(() => import('@/pages/NotBooked'));

export const DEFAULT_ROUTE = '/dashboard';
export const ADMINISTRATION_CARDS = '/administration/cards';
export const ADMINISTRATION_CONTRACT = '/administration/contract';
export const CHARGING_INVOICES = '/invoices';
export const CHARGING_SESSIONS = '/charging/sessions';
export const SIGNUP = '/signup';
export const NOT_FOUND = '/not-found';
export const NO_PERMISSIONS = '/unauthorized';
export const NOT_AVAILABLE = '/not-available';

export const routes = [
  DEFAULT_ROUTE,
  ADMINISTRATION_CARDS,
  ADMINISTRATION_CONTRACT,
  CHARGING_INVOICES,
  CHARGING_SESSIONS,
  SIGNUP,
  NOT_FOUND,
  NO_PERMISSIONS,
  NOT_AVAILABLE,
];

export const pageTitles: { page: string; title: string }[] = [
  { page: DEFAULT_ROUTE, title: 'Overview' },
  { page: ADMINISTRATION_CARDS, title: 'Administration Charging Cards' },
  { page: ADMINISTRATION_CONTRACT, title: 'Administration Charging Contract' },
  { page: CHARGING_INVOICES, title: 'Invoices' },
  { page: CHARGING_SESSIONS, title: 'Charging Activity' },
  { page: SIGNUP, title: 'Sign up' },
];

export const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppLayout />}>
      <Route
        path={DEFAULT_ROUTE}
        errorElement={<ErrorBoundary />}
        element={<DashboardPage />}
      />
      <Route
        path={ADMINISTRATION_CARDS}
        errorElement={<ErrorBoundary />}
        element={<CardsPage />}
      />
      <Route
        path={ADMINISTRATION_CONTRACT}
        errorElement={<ErrorBoundary />}
        element={<ContractPage />}
      />
      <Route
        path={CHARGING_INVOICES}
        errorElement={<ErrorBoundary />}
        element={<InvoicesPage />}
      />
      <Route
        path={CHARGING_SESSIONS}
        errorElement={<ErrorBoundary />}
        element={<SessionsPage />}
      />
      <Route
        path={SIGNUP}
        errorElement={<ErrorBoundary />}
        element={<SignupPage />}
      />
      <Route
        path={NOT_FOUND}
        errorElement={<ErrorBoundary />}
        element={<NotFound />}
      />
      <Route
        path={NO_PERMISSIONS}
        errorElement={<ErrorBoundary />}
        element={<NoPermissions />}
      />
      <Route
        path={NOT_AVAILABLE}
        errorElement={<ErrorBoundary />}
        element={<NotBookedState />}
      />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
